import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://cbcapitalbe.orderupfast.com/',
  // baseURL:"https://seashell-app-6qwpl.ondigitalocean.app/",
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});
// Add a request interceptor to modify headers before a request is sent
instance.interceptors.request.use(
  (config) => {
    // Get token from local storage
    const token = localStorage.getItem('token');
    // If token exists, add Authorization header to the request config headers
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle errors
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("error in api call ",error);
    // Check if the error is a 401 Unauthorized error
    if (error.response.status === 401) {
      // Redirect the user to the login page
      window.location.href = '/';
    }
    return Promise.reject(error);
  }
);

export default instance;